@import "./_include/_color.sass";
@import "./_include/scroll.sass";

@media screen and (min-width: 768px) {
  .main-nav-right #main-nav {
    &>li {
      &>a {
        &:after {
          display: none; } } } }
  .links {
    position: fixed;
    z-index: 100;
    transform: translateX(calc(-100% + 82px));
    @include dura(.5s);
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%,100%);
      z-index: 2; }
    &:hover {
      transform: translateX(0);
      &:after {
        transform: translateX(-100%); } } } }


@media screen and (max-width: 1195px) {
  .main-nav-right {
    #main-nav {
      &>li {
        min-width: 70px;
        max-width: 70px;
        margin-right: 15px; } } } }



@media screen and (max-width: 980px) {
  .site-logo {
    width: 190px; }
  .main-nav-right {
    #main-nav {
      &>li {
        min-width: 60px;
        max-width: 60px;
        margin-right: 10px;
        &>a {
          font-size: 14px; } } } }

  // #footer
 }  //   .f_menu

@media screen and (max-width: 768px) {
  h2.index_title {
    font-size: 50px;
    &:after {
      width: 70px;
      height: 5px; } }

  #mobile-nav-toggle {
    position: absolute;
    margin: 0;
    top: 35px;
    right: 20px;
    transform: translateY(-50%);
    i {
      color: $color_white; } }
  #mobile-nav {
    i {
      height: 42px;
      top: 0;
      width: 100%;
      &:before {
        position: absolute;
        right: 10px;
        bottom: 50%;
        transform: translate(0,50%); } }
    .sub-menu {
      background-color: $color_blue;
      a {
        color: $color_white; } } }

  #main-nav-container {
    display: none; }
  .site-logo {
    margin-bottom: 0;
    height: 70px;
    z-index: 4;
    width: 100%; }

  .index_wrap {
    padding: 30px 0;
    .-flex {
      font-size: 0;
      margin: 0 -5px; }
    .content,picture {
      width: 100%!important;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 14px; } }


  #news_wrapper {
    padding-left: 5%;
    padding-right: 5%;
    .news_list {
      .date {
        font-size: 14px;
        line-height: 18px; }
      a {
        font-size: 14px;
        line-height: 18px; } } }

  #about_wrapper {
    padding-left: 5%;
    padding-right: 5%;
    .content {
      padding-right: 0; }
    .m_btn {
      margin-right: 0; } }

  #course_wrapper {
    .info {
      width: calc(100% - 30px);
      max-width: 350px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      .content,picture {
        padding: 0; } } }

  .map {
    padding-bottom: 50%; }

  #footer {
    padding: 0;
    display: none;
    .logo {
      display: none;
 }      // a
    .f_menu {
      display: none; } } }



@media screen and (max-width: 600px) {
  // main
  // #main
  //   width: 100%
  //   margin: 0


  body {
    padding-bottom: 85px; }

  .m_btn {
    float: right; }

  // 倒數
  #exam {
    @include size(100%,85px);
    padding-top: 0;
    .call {
      // display: none
      @include size(85px,85px);
      right: 0;
      left: unset; }
    .clock_num {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 70px);
      transform: translateY(50%);
      li {
        margin-bottom: 0;
        width: calc(100% / 3);
        span {
          font-size: 18px;
          padding: 0; }
        p {
          font-size: 14px; }
        &:after {
          font-size: 14px; } } } }

  // slide
  .customize {
    width: calc(100%);
    .swiper-slide {
      height: 300px; } }

  .links {
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    position: relative;
    li {
      width: calc(100% / 5 - 2px);
      padding-left: 0;
      padding-right: 0; } } }


