@import "./_include/_color.sass";
@import "./_include/scroll.sass";


* {
  position: relative;
  font-family: $font!important;
  vertical-align: top;
  box-sizing: border-box; }

h1,h2,h3,h4,h5,h6,p {
  margin: 0; }

html,body {
  background: none; }

body.home {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%,100%);
  overflow: hidden;
  &.loaded {
    position: relative;
    @include size(100%,auto);
    overflow: auto; } }

body:not(.home) .bg_lines {
  display: none; }


#main {
  overflow-x: hidden; }


.kc_column, .kc_column_inner {
  padding: 0; }

.-container {
  @extend %container_width; }

.-flex {
  @extend %div_flex; }


// bg
.bg_lines {
  position: absolute;
  @include size(100%,100%);
  background-color: #eeeeee;
  @extend %bg_line;
  &:before {
    left: calc(100% / 6 - 1px); }
  &:after {
    left: calc(100% / 6 * 5 - 1px); }
  .lines {
    position: unset;
    @extend %bg_line;
    &:before {
      left: calc(100% / 6 * 2 - 1px); }
    &:after {
      left: calc(100% / 6 * 4 - 1px); }
    .line {
      position: unset;
      @extend %bg_line;
      &:after {
        display: none; }
      &:before {
        left: calc(100% / 6 * 3 - 1px); } } } }

// main
// #main
//   width: calc(100% - 100px)
//   margin: 0

