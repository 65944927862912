@import "./_include/_color.sass";
@import "./_include/scroll.sass";

body:not(.home) {
  .loading {
    display: none; } }

.loading {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%,100%);
  background-color: $color_blue;
  z-index: 1000;
  @include dura(.8s);
  .info {
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%,50%);
    img {
      display: block;
      @include size(200px,auto); }
    p {
      color: $color_white;
      text-align: center;
      margin-top: 15px;
      animation: bling 1s infinite; } } }

body.loaded {
  .loading {
    transform: scale(0);
    opacity: 0; } }

// title
h2.index_title {
  margin: 0;
  font-size: 65px;
  font-family: Lato;
  margin-bottom: 60px;
  text-transform: uppercase;
  font-weight: 900;
  display: table;
  padding-right: 20px;
  overflow: hidden;
  color: transparent;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    @include size(100%,100%);
    @include dura(.5s);
    transform-origin: right center;
    background-color: $color_title_blue; }
  &:after {
    content: "";
    display: block;
    margin-top: 30px;
    width: 95px;
    height: 7px;
    background-color: $color_title_blue;
    opacity: 0;
    @include dura(.5s); }
  &.t_i {
    transition-delay: .5s;
    color: $color_title_blue;
    &:before {
      right: 0;
      animation: title_in .5s .5s both; }
    &:after {
      transition-delay: .5s;
      opacity: 1; } } }

// more
.m_btn {
  color: $color_title_blue;
  font-family: Lato;
  border: solid 3px $color_title_blue;
  text-transform: uppercase;
  padding: 7px 30px;
  font-weight: 600;
  display: table;
  overflow: hidden;
  transition-delay: .3s;
  @include dura(.5s);
  &:after {
    content: "";
    display: block;
    position: absolute;
    @include size(115%,100%);
    background-color: $color_title_blue;
    right: 50%;
    bottom: 50%;
    transform: skewX(0deg) translate(-115%,50%);
    @include dura(1s); }
  &:hover {
    color: $color_white;
    background-color: $color_title_blue;
    &:after {
      transform: skewX(15deg) translate(130%,50%);
      background-color: $color_white; } } }


// 倒數
#exam {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: $color_blue;
  @include size(100px,100%);
  padding-top: 100px;
  .clock_num {
    margin: 0;
    padding-left: 0;
    color: $color_white;
    text-align: center;
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 100%;
    transform: translateY(40%);
    li {
      list-style-type: none;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0; }
      &:after {
        content: "days";
        display: block;
        text-transform: uppercase;
        font-size: 18px; }
      span {
        font-size: 25px;
        padding: 8px 0;
        display: block; } }
    p {
      font-size: 16px;
      margin: 0; } }
  .call {
    position: absolute;
    bottom: 0;
    left: 0;
    @include size(100%,100px);
    background-color: $color_dark_blue;
    overflow: hidden;
    a {
      display: block;
      @include size(100%,100%);
      &:hover {
        img {
          animation: swing .2s 2; } } }
    img {
      @include size(34px,34px);
      position: absolute;
      display: block;
      bottom: 50%;
      right: 50%;
      transform: translate(50%,50%); } } }

// 頭部
#header {
  min-height: unset;
  background-color: $color_blue;
  font-size: 0; }

#header-container {
  padding: 0; }

#main-nav-container {
  @extend %clearfix;
  float: right;
  margin-right: 100px; }

#main-nav-container,.site-logo {
  font-size: initial;
  display: inline-block;
  vertical-align: middle; }

.main-nav-right #main-nav {
  float: left;
  margin-top: 0;
  font-size: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100px;
  // 第一層選單
  &>li {
    font-size: initial;
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
    max-width: 85px;
    min-width: 85px;
    &:last-child {
      margin-right: 0; }
    &>a {
      color: $color_white;
      padding: 0px!important;
      white-space: normal;
      height: 100px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        @include size(100%,6px);
        background-color: $color_white;
        transform: scaleX(0);
        transform-origin: center center;
        opacity: 0;
        @include dura(.3s); }
      &:hover {
        &:before {
          opacity: 1;
          transform: scaleX(1); } } }

    a {
      font-size: 16px; } }
  .sub-menu {
    a {
      &:hover {
        color: $color_white;
        background-color: $color_dark_blue; } } } }

.site-logo {
  background-color: $color_dark_blue;
  width: 300px;
  height: 100px;
  margin-top: 0;
  float: none!important;
  a {
    display: block;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%,50%);
    @include size(100%,100%);
    img {
      width: 80%;
      display: block;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%,50%); } } }

// 輪播圖
.customize {
  width: calc(100% - 100px);
  margin: 0;
  .swiper-slide {
    height: 620px;
    background-size: cover;
    background-position: center center; } }

.links {
  position: absolute;
  left: 0;
  bottom: 50px;
  display: flex;
  background-color: #1b1b1b;
  z-index: 1;
  margin: 0;
  font-size: 0!important;
  li {
    font-size: initial;
    position: unset;
    display: inline-block;
    vertical-align: bottom;
    list-style-type: none;
    padding: 20px 10px;
    &:nth-child(2) {
      img {
        @include size(65px,25px); } }
    img {
      display: block;
      @include size(auto,25px);
      margin: 0 auto;
      margin-bottom: 5px; }
    a {
      display: block;
      color: $color_white;
      text-align: center;
      font-size: 12px; }
    &.fb_menu {
      height: auto;
      .fb_list {
        padding: 10px 20px;
        display: none;
        position: absolute;
        bottom: 100%;
        left: 0;
        overflow-x: scroll;
        white-space: nowrap;
        @include size(100%,auto);
        background-color: rgba(#1b1b1b,.7);
        &::-webkit-scrollbar {
          display: none; }
        li {
          padding: 10px 0;
          &:last-child {
            &>a {
              border: none; } }
          &>a {
            border-right: solid 1px $color_white;
            padding: 0 15px; } } } } } }

// 最新消息
.news_item {
  display: none; }

.index_wrap {
  padding: 70px 0;
  .-flex {
    font-size: 0;
    margin: 0;
    &.reverse {
      .content {
        width: calc(45% - 1px); }
      picture {
        width: calc(55% - 1px); } } }
  .content,picture {
    font-size: initial;
    display: inline-block;
    @extend %clearfix;
    z-index: 2;
    color: $color_txt; }
  .content {
    width: calc(55% - 1px);
    font-size: 17px; }
  picture {
    width: calc(45% - 1px);
    overflow: hidden;
    img {
      display: block;
      @include size(100%,auto);
      @include dura(.7s); }
    &:hover {
      img {
        transform: scale(1.1); } } } }

#news_wrapper {
  padding-left: 10%;
  .news_list {
    margin: 0;
    padding-left: 0;
    a {
      color: $color_txt;
      font-size: 17px;
      line-height: 20px;
      @include dura(.5s); }
    li {
      list-style-type: none;
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
      &:hover {
        .title {
          background-color: $color_title_blue;
          transform: translateY(-5px);
          box-shadow: 5px 5px 15px #999;
          a {
            color: $color_white; } } } }
    .date {
      padding: 5px;
      line-height: 20px;
      color: $color_txt;
      margin-right: 10px;
      font-size: 17px; }
    .title {
      padding: 5px;
      @include dura(.5s); } } }

#about_wrapper {
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    @include size(90%,100%);
    background-color: $color_white; }
  .content {
    padding-left: 5%;
    padding-right: 100px; }
  .m_btn {
    float: right;
    margin-right: 15%;
    margin-top: 40px; } }

#course_wrapper {
  @extend %container_width;
  .info {
    width: calc(100% / 3 - 31px);
    margin: 15px;
    &.reverse {
      flex-direction: column-reverse; }
    picture,.txt {
      overflow: hidden;
      width: 100%;
      &:after {
        content: "";
        display: block;
        padding-bottom: 70%; }
      img {
        display: block;
        width: 100%;
        height: auto;
        position: absolute;
        right: 50%;
        bottom: 50%;
        transform: translate(50%,50%); } }
    picture {
      &:hover {
        img {
          transform: translate(50%,50%) scale(1.1); } } }
    .txt {
      background-color: $color_white;
      display: block;
      @include dura(.5s);
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        @include size(100%,100%);
        background-color: $color_blue;
        transform: skewX(90deg) translate3d(0,0,0);
        transform-origin: center center;
        opacity: 0;
        @include dura(.5s); }
      &:hover {
        &:before {
          transform: skewX(0deg) translate3d(0,0,0);
          opacity: 1; }
        h4 {
          color: $color_white;
          p {
            &:before {
              background-color: $color_white; } } } }
      h4 {
        position: absolute;
        color: $color_txt;
        bottom: 50%;
        right: 50%;
        width: 90%;
        text-align: center;
        transform: translate(50%,45%);
        font-size: 22px;
        @include dura(.3s);
        p {
          font-size: 16px;
          &:before {
            @include dura(.3s);
            content: "";
            display: block;
            width: 150px;
            height: 1px;
            background-color: $color_txt;
            margin: 20px auto; } } } } } }

.map {
  @include size(100%,0);
  padding-bottom: 30%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%,100%); } }

#footer {
  background-color: $color_blue;
  .logo {
    display: block;
    @include size(68px,59px);
    margin-top: 44px;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    img {
      display: block;
      @include size(100%,auto); } }
  .f_menu {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 40px;
    padding-left: 0;
    &>li {
      display: inline-block;
      width: calc(100% / 7 - 21px);
      margin: 0 10px;
      &>a {
        font-size: 18px;
        color: rgba($color_white,.5);
        margin-bottom: 30px;
        display: block; }
      .sub-menu {
        display: block!important;
        padding: 0;
        li {
          list-style-type: none;
          margin-bottom: 30px;
          a {
            font-size: 18px;
            color: $color_white;
            white-space: normal; } } } } } }
