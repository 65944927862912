// 字型
$font: "Microsoft JhengHei";
// 主色
$color_white: white;
$color_black: black;
$color_gray: #282828;
$color_blue: #2e61bf;
$color_dark_blue: #272d5c;
$color_title_blue: #1d1971;
$color_txt: #424242;
// 字體大小
$font_content_size: 16px;
$font_title_size: 20px;


@mixin size($w,$h) {
  width: $w;
  height: $h; }

@mixin dura($t) {
  transition-duration: $t; }

%container_width {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto; }

%div_flex {
  display: flex;
  flex-wrap: wrap;
  &.still {
    display: flex; }
  &.reverse {
    flex-direction: row-reverse; } }

@media screen and (max-width: 768px) {
  %container_width {
    width: 90%; }
  %div_flex {
    display: block;
    &.reverse {
      flex-direction: row; } } }

%bg_line {
  &:before,&:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #e0e0e0; } }


%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }
