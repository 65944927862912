@import "./_include/_color.sass";
@import "./_include/scroll.sass";


body {
  @include fadeIn; }

// .links
//   +fadeIn

h2.index_title {
 }  // +fadeInRight

#news_wrapper .news_list li {
  @include fadeIn; }

.m_btn {
  @include fadeInLeft; }

#course_wrapper .info {
  @include fadeInDown; }

.content p {
  @include fadeIn; }

#footer .f_menu {
  @include fadeInDown; }

.index_wrap picture {
  @include fadeInLeft; }

.banner {
  img {
    display: block;
    width: calc(100% - 100px); }
  h1 {
    display: table;
    text-shadow: 2px 2px 5px #fff;
    color: #333;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 3px;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%,50%); } }

.wp-pagenavi {
  width: 100%;
  padding: 30px 0;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center; }

.owl-nav-arrow.owl-theme .owl-controls .owl-buttons div:before {
  color: #2e61bf; }

.owl-nav-arrow.owl-theme .owl-controls .owl-buttons div {
  margin: 0; }

.class-carousel {
  max-width: 700px;
  margin: 0 auto;
  padding: 50px 0;
  width: 80%;
  padding-right: 10%;
  &:after {
    content: "";
    display: table;
    clear: both; }
  img {
    display: block;
    width: 100%; }
  .owl-controls {
    position: absolute;
    opacity: 1;
    bottom: 50%;
    width: 100%;
    right: 50%;
    transform: translate(50%,50%); } }

.owl-nav-arrow.owl-theme .owl-controls .owl-buttons div {
  color: transparent;
  &:before {
    color: #2e61bf; } }




@media screen and (max-width: 600px) {
  .class-carousel {
    padding-right: 0; }
  .banner {
    img {
      width: 100%; } } }
