@mixin fadeIn {
  opacity: 0;
  &.-fadeIn {
    opacity: 1!important; } }

@mixin fadeInDown {
  transform: translateY(-50px);
  opacity: 0;
  &.-fadeInDown {
    opacity: 1!important;
    transform: translateY(0); } }

@mixin fadeInUp {
  transform: translateY(30px);
  opacity: 0;
  &.-fadeInUp {
    opacity: 1!important;
    transform: translateY(0); } }

@mixin fadeInLeft {
  transform: translateX(-50px);
  opacity: 0;
  &.-fadeInLeft {
    opacity: 1!important;
    transform: translateX(0); } }

@mixin fadeInRight {
  transform: translateX(50px);
  opacity: 0;
  &.-fadeInRight {
    opacity: 1!important;
    transform: translateX(0); } }
