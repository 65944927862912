@import "./_include/_color.sass";
@import "./_include/scroll.sass";

#main {
  margin-top: 0; }


.page-title {
  font-size: 20px;
  color: #1d1971;
  font-weight: bold;
  padding: 15px 0;
  border-bottom: 1px solid #1d1971;
  margin-bottom: 30px; }

.archive {
  #main-container {
    padding: 50px 0; }
  .col-md-12 {
    display: flex;
    flex-wrap: wrap; }
  .page-header {
    display: none; }
  .post {
    padding: 20px;
    width: calc(100% / 4);
    margin: 0;
    .entry-header {
      &>span {
        display: block;
        margin-bottom: 10px;
        color: $color_black; } }
    .featured-image {
      margin: -20px -20px 20px -20px; }
    .entry-content {
      display: none; }
    .entry-title a {
      font-size: 18px; }
    &:hover {
      z-index: 2;
      box-shadow: 0 0 20px $color_black;
      .entry-title a {
        color: $color_blue; } }
    .more {
      text-transform: uppercase;
      color: $color_blue;
      position: absolute;
      right: 20px;
      bottom: 20px;
      &:before {
        content: "-";
        margin-right: 10px; } } } }

.single-post {
  #main-container {
    padding: 50px 5%;
    width: calc(100% - 100px);
    margin: 0; }
  .col-md-12 {
    width: 100%; } }

.page-banner {
  width: calc(100% - 100px); }

.page-container {
  width: calc(100% - 100px);
  padding: 50px 5vw;
  &.fluid {
    padding-left: 0;
    padding-right: 0; } }

.transcript, .edu-concept {
  margin: 0; }

.tutor,.talent-class {
  .row {
    margin: 0;
    [class^="col"] {
      margin: 0;
      padding: 0; } }
  .entitle {
    color: $color_black;
    font-weight: bold;
    letter-spacing: 4px;
    font-size: 30px;
    line-height: 40px;
    span {
      letter-spacing: 2px;
      font-size: 20px;
      font-weight: normal;
      color: red;
      display: block;
      line-height: 30px; } }
  picture {
    overflow: hidden;
    display: block;
    height: 100%;
    img {
      @include size(auto,100%);
      position: absolute;
      right: 50%;
      bottom: 50%;
      transform: translate(50%,50%); } }
  &.bg {
    background-image: url('/wp-content/uploads/class_bg.svg');
    background-position: center 0%;
    background-repeat: no-repeat;
    background-size: cover; }
  .transcript {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5%;
    img {
      display: block;
      width: calc(100% - 30px);
      margin: 0 15px 30px 15px; } }
  .onevsone {
    padding: 0 5%;
    picture,.info,.swiper-container {
      width: 50%; }
    picture,.swiper-container {
      img {
        position: unset;
        @include size(100%,auto);
        transform: unset; } }
    .info {
      background-color: $color_blue;
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        margin-bottom: 20px;
        color: $color_white;
        padding-bottom: 10px;
        border-bottom: solid 1px $color_white; }
      p {
        color: $color_white; } } }
  .content {
    img {
      display: block;
      width: 100%; }
    .txt {
      position: absolute;
      top: 30px;
      right: 50%;
      transform: translateX(50%);
      text-align: center;
      max-width: 420px;
      color: $color_white; } }
  .con {
    background-color: #187bb5; }
  .en-box {
    picture {
      h2 {
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%,50%);
        z-index: 2;
        margin: 0;
        font-size: 40px;
        font-weight: bold;
        width: 100%;
        text-align: center;
        color: #000; } } }
  .en-box:not(.reverse) {
    .con {
      transform: translateY(50px); } }
  .en-box.reverse {
    picture {
      transform: translateY(50px); } }
  &.math {
    margin-top: -40px;
    background-color: #484b54;
    padding: 0 5%;
    h2 {
      padding-top: 70px;
      padding-bottom: 30px;
      color: $color_white; }
    .step {
      width: calc(100% / 5 - 30px);
      margin: 0 15px 30px 15px;
      .title {
        font-size: 20px;
        text-align: center;
        padding: 20px 0; }
      p {
        font-size: 18px;
        text-align: justify;
        color: $color_white; } } } }

.talent-class .row-blue .con, .class-category .row-blue .con, .tutor .row-blue .con {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-left: 0;
  max-width: unset;
  padding: 40px 0;
  min-height: 430px; }

.talent-class .row-blue .con ul, .class-category .row-blue .con ul, .tutor .row-blue .con ul {
  max-width: 70%;
  margin-bottom: 0;
  margin-left: 10%;
  li {
    &:last-child {
      margin-bottom: 0; } } }

.row-blue {
  display: block; }

.talent-class h2, .class-category h2, .tutor h2 {
  margin-top: 0;
  margin-bottom: 50px; }

.talent-row {
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 5%;
  padding-right: 5%;
  .col-xs-6 img {
    display: block;
    width: 100%; } }

.place {
  .swiper-slide {
    img {
      display: block;
      width: 100%; } }

  .gallery-top {
    height: 80%;
    width: 100%; }

  .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0; }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4; }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1; }

  .tab-list {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 10px;
    li {
      list-style-type: none;
      font-size: 18px;
      padding: 10px 20px;
      cursor: pointer;
      &:last-child {
        &:after {
          display: none; } }
      &:after {
        content: "";
        display: table;
        @include size(1px,16px);
        background-color: $color_black;
        position: absolute;
        right: 0;
        bottom: 50%;
        transform: translate(50%,50%); }
      &.active,&:hover {
        color: $color_blue;
        font-weight: bold; }
 } }      // &:not(.active)
  .tab-contents {
    .content {
      display: none;
      &.active {
        display: block; } } } }

.own-env {
  .school {
    display: flex;
    margin: 0 -15px;
    margin-bottom: 70px;
    margin-top: 70px;
    picture,ul {
      width: calc(50% - 30px);
      margin: 0 15px; }
    picture {
      display: block;
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        @include size(100%,100%);
        background-color: $color_blue; }
      img {
        display: block;
        width: 100%;
        z-index: 2; } }
    ul {
      padding: 40px;
      background-color: #fff;
      li {
        list-style-type: none;
        margin-bottom: 10px;
        color: $color_black;
        font-size: 16px;
        display: flex;
        span {
          display: block;
          margin-right: 10px;
          min-width: 80px;
          font-weight: bold; }
        a {
          color: $color_black; }
        h3 {
          border-bottom: solid 2px $color_blue;
          display: table;
          color: $color_blue;
          padding-bottom: 7px;
          font-weight: bold;
          margin-bottom: 30px; } } } } }

.timetable {
  margin: 40px 0;
  img {
    display: block;
    width: 100%;
    padding: 0 5%;
    padding-top: 30px; } }

// tab
.kc_row:not(.kc_row_inner).page-container {
  width: calc(100% - 100px);
  padding-top: 0; }

.kc_tabs>.kc_wrapper>.kc_tabs_nav {
  background: none;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #187bb2 !important;
  box-shadow: 0px 1px 2px #777; }

.kc_tabs_nav>li>a, .nav-pills>li>a {
  padding: 0 10px; }

.kc_tabs_nav>.ui-tabs-active, .kc_tabs_nav>.ui-tabs-active:hover, .kc_tabs_nav>.ui-tabs-active>a, .kc_tabs_nav>.ui-tabs-active>a:hover,.kc_tabs_nav>.ui-tabs-active>a, .kc_tabs_nav>li:hover, .kc_tabs_nav>li>a:hover {
  background: none;
  border-bottom: solid 5px #187bb2;
  font-weight: bold; }

.kc_tabs_nav~.kc_tab.ui-tabs-body-active {
  background: none; }

.kc_tabs_nav~.kc_tab .kc_tab_content {
  padding: 40px 0;
  padding-bottom: 0;
  @extend %clearfix; }

.talent-class .row-blue, .class-category .row-blue, .tutor .row-blue {
  background: none; }

.teacher-row {
  padding: 50px calc(100px + 30px) 50px 30px;
  .kc_tab_content {
    width: 100%!important; }
  .kc-raw-code {
    width: calc(100% / 3 - 30px);
    margin: 0 15px 30px 15px;
    text-align: center; }
  .teacher {
    picture {
      display: block;
      @include size(80%,auto);
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 3px 3px 8px #888;
      border: solid 7px $color_blue;
      margin: 0 auto;
      img {
        @include size(100%,auto);
        @include dura(.8s);
        position: absolute;
        transform: translate(50%,50%);
        right: 50%;
        bottom: 50%; }
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%; } }
    .txt {
      padding-top: 20px;
      padding-bottom: 10px;
      p {
        border: solid 1px $color_blue;
        display: table;
        margin: 0 auto;
        padding: 5px 10px;
        border-radius: 20px;
        color: $color_blue;
        font-size: 12px; }
      h4 {
        margin-top: 10px;
        font-size: 25px;
        padding: 10px 0;
        color: $color_black;
        font-weight: bold;
        &:after {
          content: "老師";
          font-size: 16px;
          margin-left: 5px; } } }
    .more {
      background-color: $color_blue;
      color: $color_white;
      padding: 5px 20px;
      border-radius: 30px;
      display: table;
      margin: 0 auto; }
    // &:hover
    //   picture
    //     img
 } }    //       transform: translate(50%,50%) scale(1.2)

.teacher-info {
  padding: 50px 5%;
  .info-header {
    align-items: flex-start;
    picture {
      width: calc(30% - 30px);
      margin: 0 15px;
      border: solid 5px $color_blue;
      display: block;
      img {
        display: block;
        width: 100%; } }
    .txt {
      border: dashed 8px $color_blue;
      width: calc(70% - 30px);
      margin: 0 15px;
      padding: 50px 50px 30px 50px;
      .name {
        display: flex;
        align-items: flex-end;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: solid 1px rgba($color_blue,.2);
        p {
          font-size: 12px;
          margin-right: 10px;
          background-color: $color_blue;
          color: $color_white;
          padding: 0 5px; }
        h3 {
          font-size: 25px;
          font-weight: bold;
          color: $color_black;
          letter-spacing: 2px; } }
      .simple {
        color: $color_black;
        letter-spacing: 1px;
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: solid 1px rgba($color_blue,.2); }
      .list {
        margin: 0;
        padding: 0;
        li {
          list-style-type: none;
          display: flex;
          color: $color_black;
          span {
            display: block;
            min-width: 150px;
            color: $color_dark_blue;
            font-weight: bold; }
          ul {
            margin: 0;
            padding: 0;
            li {
              margin-bottom: 10px;
              &:before {
                content: "";
                display: block;
                min-width: 20px;
                @include size(20px,20px);
                background-image: url('/assets/images/logo-blue.svg');
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 10px; } } } }
        &>li {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: solid 1px rgba($color_blue,.2); } } } } }


@media screen and (min-width:768px) {
  .archive {
    .post {
      width: calc(100% / 4 - 10px);
      margin: 0 5px 20px 5px; }
    .col-md-12 {
      width: 100%; }
    #main-container {
      width: calc(100% - 100px);
      margin: 0;
      padding-left: 5%;
      padding-right: 5%; } }

  .tutor .row,.talent-class .row {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    &.reverse {
      flex-direction: row-reverse; } } }

// tab




@media screen and (max-width:768px) {
  .archive {
    .post {
      width: calc(100% / 2);
      margin: 0; }
    .col-md-12 {
      width: 100%; }
    #main-container {
      width: calc(100% - 100px);
      margin: 0;
      padding-left: 5%;
      padding-right: 5%; } }
  .tutor-swiper {
    width: calc(100% - 100px);
    margin: 0;
    img {
      display: block;
      width: 100%; } }
  .talent-class .row-blue .con ul, .class-category .row-blue .con ul, .tutor .row-blue .con ul {
    max-width: 80%;
    margin-left: 0; }

  .tutor {
    // .row
    picture {
      img {
        position: unset;
        transform: unset;
        @include size(100%,auto); } }
    .content {
      img {
        display: block;
        width: 100%; }
      .txt {
        position: relative;
        top: 0px;
        right: 0;
        transform: translateX(0%);
        text-align: center;
        max-width: 90%;
        margin: 0 auto;
        padding: 10px 0;
        color: $color_black; } }
    h2.entitle {
      display: block;
      font-size: 24px;
      span {
        font-size: 14px; } }
    .en-box:not(.reverse) {
      .con {
        transform: translateY(0px); } }
    .en-box.reverse {
      picture {
        transform: translateY(0px); } }
    .en-box {
      picture {
        h2 {
          font-size: 30px; } } }
    &.math {
      .row {
        display: flex;
        flex-wrap: wrap; }
      .step {
        width: calc(100% / 2 - 30px); } } }


  .timetable {
    margin-bottom: 30px;
    margin-top: 0; }

  .talent-class h2, .class-category h2, .tutor h2 {
    margin-top: 0;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 45px!important; } }

  .place {
    .tab-list {
      li {
        font-size: 16px;
        padding: 10px; } } }

  .own-env .school {
    display: block;
    margin: 0 0 40px 0;
    picture,ul {
      width: 100%;
      margin: 0; }
    picture {
      &:after {
        display: none; } }
    ul {
      li {
        font-size: 14px;
        h3 {
          font-size: 18px; } } } }

  .tutor .onevsone, .talent-class .onevsone {
    margin-bottom: 30px;
    picture,.info,.swiper-container {
      width: 100%; }
    .info {
      padding-top: 30px;
      padding-bottom: 30px; } }

  .teacher-row {
    display: flex;
    .kc-raw-code {
      width: calc(100% / 2 - 30px); }
    .teacher {
      picture {
        width: 100%;
        img {
          position: absolute;
          transform: translate(50%,50%); } }
      .txt {
        h4 {
          font-size: 20px;
          &:after {
            font-size: 14px; } } } } }

  .teacher-info {
    padding: 20px 5%;
    .info-header {
      picture {
        width: calc(100% - 30px);
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 30px; }
      .txt {
        width: calc(100% - 30px);
        padding: 30px 30px 10px 30px;
        .list {
          &>li {
            display: block;
            span {
              margin-bottom: 10px; } } } } } } }



@media screen and (max-width:600px) {
  .archive,.single-post {
    #main-container {
      width: 100%;
      margin: 0;
      padding-left: 5%;
      padding-right: 5%; } }

  .single-post {
    .post {
      padding: 0px;
      .featured-image {
        margin: 0 0 30px 0; } } }

  .teacher-row {
    padding: 0 30px; }

  .page-banner {
    width: 100%; }

  .page-container {
    width: 100%;
    padding: 30px 5%; }

  .kc_row:not(.kc_row_inner).page-container {
    width: 100%; }

  .tutor-swiper {
    width: calc(100%); }


  .tutor,.talent-class {
    &.math {
      .row {
        display: flex;
        flex-wrap: wrap; }
      .step {
        width: calc(100% - 30px);
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        p {
          text-align: center;
          line-height: 30px; } } } } }

.activities {
  .page-container {
    padding-top: 0;
    padding-bottom: 0; }
  .page-banner {
    position: relative;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center center;
    @media (max-width: 768px) {
      min-height: calc(100vh - 70px); }
    @media (max-width: 600px) {
      min-height: calc(100vh - 155px); }
    h2,p {
      position: relative;
      z-index: 2;
      text-align: center;
      font-family: Roboto, 微軟正黑體;
      font-weight: 600; }
    h2 {
      margin-bottom: 30px;
      font-size: 33px;
      color: #fff;
      @media (max-width: 768px) {
        font-size: 20px; }
      p {
        margin-top: 25px;
        font-size: 23px;
        font-style: italic;
        @media (max-width: 768px) {
          font-size: 16px; }
        span:first-of-type {
          color: #ffff99; }
        span:last-of-type {
          color: #ccffff; } } }
    p {
      margin-bottom: 30px;
      font-size: 20px;
      color: #fff;
      font-weight: 200;
      @media (max-width: 768px) {
        font-size: 15px;
        margin-bottom: 20px; }
      @media (max-width: 600px) {
        font-size: 13px; } }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(black,.3); } }

  .pic {
    margin-bottom: 15px;
    img {
      display: block;
      width: 100%; } }

  .activities-info {
    padding: 70px 0;
    position: relative;
    @media (max-width: 768px) {
      padding: 50px 0 20px; }
    &[style] {
      margin: 0 -5vw;
      padding: 70px 5vw;
      background-size: cover;
      @media (max-width: 768px) {
        padding: 10px 5vw; }
      p,li {
        color: #000;
        span {
          font-weight: bold; } }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#fff,.88); } }
    h2 {
      margin-bottom: 50px;
      color: rgb(64, 84, 178);
      font-size: 30px;
      text-align: center;
      z-index: 3;
      font-weight: bold;
      @media (max-width: 768px) {
        margin-bottom: 20px; }
      span {
        display: block;
        margin-top: 20px;
        font-size: 18px;
        color: rgb(35, 164, 85);
        font-weight: 500; } }
    h3 {
      font-size: 24px;
      color: #942d96;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 20px; }
    h2.title {
      font-size: 23px;
      color: #333;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 20px;
        margin: 30px 0; }
      &:after,&:before {
        content: "";
        display: block;
        width: 35%;
        border-top: solid 1px #333;
        position: absolute;
        bottom: calc(50% - 0.5px); }
      &:after {
        right: 0; }
      &:before {
        left: 0; } }
    p,li {
      font-size: 14px;
      color: rgb(122, 122, 122);
      text-align: center;
      line-height: 27px;
      z-index: 3;
      b {
        font-weight: bold;
        color: #800080; }
      &.text-left {
        text-align: left; } }
    .talk {
      p,li {
        font-size: 18px;
        line-height: 29px;
        @media (max-width: 768px) {
          font-size: 14px; } } }
    li {
      text-align: left;
      span {
        color: #0000ff; } }
    .row {
      margin-top: 30px;
      padding-bottom: 30px;
      z-index: 3; } }
  .swiper-slide {
    cursor: grab;
    img {
      display: block;
      width: 100%; } }
  .swiper-container {
    padding-bottom: 40px; }
  .swiper-pagination {
    bottom: 3px;
    span {
      width: 6px;
      height: 6px; } }
  .swiper-pagination-bullet-active {
    background-color: #333; }
  .swiper-button-next.swiper-button-white,
  .swiper-button-prev.swiper-button-white {
    top: calc(50% - 30px);
    width: 20px;
    height: 20px;
    margin: 0;
    background-size: contain; }

  div.wpforms-container-full .wpforms-form .wpforms-field {
    padding: 0;
    margin-bottom: 5px; }

  div.wpforms-container-full .wpforms-form input[type=date], div.wpforms-container-full .wpforms-form input[type=datetime], div.wpforms-container-full .wpforms-form input[type=datetime-local], div.wpforms-container-full .wpforms-form input[type=email], div.wpforms-container-full .wpforms-form input[type=month], div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
    border-radius: 3px;
    border: solid 1px rgb(129, 138, 145);
    &:focus {
      border: solid 1px red; }
    &::placeholder {
      font-size: 14px; } }

  div.wpforms-container-full .wpforms-form textarea.wpforms-field-large {
    height: 75px; }

  div.wpforms-container-full .wpforms-form button[type=submit] {
    background-color: #009ee2;
    width: 100%;
    color: #fff;
    border-radius: 3px;
    border: none;
    &:hover {
      background-color: #942d96;
      border: none; } }

  div.wpforms-container-full .wpforms-form .wpforms-submit-container {
    padding-top: 0; }

  .contact.contact.contact.contact {
    p {
      display: flex; }
    p,a {
      margin-bottom: 25px;
      font-size: 18px;
      color: #54595f;
      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 5px; }
      span {
        font-weight: normal; }
      i {
        margin-right: 15px;
        color: #009ee2; }
      &:hover {
        i {
          color: #942d96; } } } } }
