@import "./_include/_color.sass";
@import "./_include/scroll.sass";
@keyframes swing {
  0% {
    right: 50%; }
  25% {
    right: 60%; }
  75% {
    right: 40%; }
  100% {
    right: 50%; } }

@keyframes title_in {
  0% {
    top: 0;
    transform: scaleX(1); }
  100% {
    transition-delay: .5s;
    transform-origin: right center;
    transform: scaleX(0); } }

@keyframes bling {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }
